import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import Dropzone from 'views/admin/job/widgets/JobDocumentsWidget/Dropzone';
import { MdUpload } from 'react-icons/md';
import {
  CertidaoRegistoComercialRelationType,
  JobDocument,
  useAddCadernetaWithAccessCodeMutation,
  useAddCertidaoRegistoComercialWithAccessCodeMutation,
  useAddCertidaoWithAccessCodeMutation,
  useCreateJobDocumentMutation,
  useUploadJobDocumentMutation,
} from '../../../../../api';
import {
  CadernetaValidationData,
  DocumentsValidationErrorTypeEnum,
  ModalCadernetaValidation,
  ModalCertidaoPerdialValidation,
  ModalDocumentsValidationError,
  ModalDocumentTypeValidationError,
} from './DocumentValidationModals';
import { ModalCertidaoRegistoComercialValidation } from './DocumentValidationModals/ModalCertidaoRegistoComercialValidation';
import { JobCategorizeFiles } from './JobCategorizeFiles';

interface JobDocumentsWidgetProps {
  jobId: string;
  documentsValidationError: DocumentsValidationErrorTypeEnum | null;
  onDocumentsValidationError: React.Dispatch<React.SetStateAction<DocumentsValidationErrorTypeEnum | null>>;
  isShowUserSelectedUnsupportedTypeModal: boolean;
  onShowUserSelectedUnsupportedTypeModal: (isShowUserSelectedUnsupportedTypeModal: boolean) => void;
  addDocumentsToPolling: (documents: JobDocument[]) => void;
  uploadedDocs: JobDocument[];
  onUploadedDocs: (uploadedDocs: JobDocument[]) => void;
}

export function JobDocumentsWidget({
  jobId,
  documentsValidationError,
  onDocumentsValidationError,
  isShowUserSelectedUnsupportedTypeModal,
  onShowUserSelectedUnsupportedTypeModal,
  addDocumentsToPolling,
  uploadedDocs,
  onUploadedDocs,
}: JobDocumentsWidgetProps) {
  const { t } = useTranslation();

  const [isCertidaoModalOpen, setIsCertidaoModalOpen] = React.useState(false);
  const [isCadernetaModalOpen, setIsCadernetaModalOpen] = React.useState(false);
  const [isCertidaoRegistoComercialModalOpen, setIsCertidaoRegistoComercialModalOpen] = React.useState(false);
  const [errorModalAdditionalText, setErrorModalAdditionalText] = React.useState<string | undefined>();

  const [createJobDocument] = useCreateJobDocumentMutation({});
  const [uploadJobDocument] = useUploadJobDocumentMutation({});
  const [addCertidaoWithAccessCode, { isLoading: isLoadingCertidaoWithAccessCode }] =
    useAddCertidaoWithAccessCodeMutation({});
  const [addCadernetaWithAccessCode, { isLoading: isLoadingCadernetaWithAccessCode }] =
    useAddCadernetaWithAccessCodeMutation({});
  const [addCertidaoRegistoComercialWithAccessCode, { isLoading: isLoadingCertidaoRegistoComercialWithAccessCode }] =
    useAddCertidaoRegistoComercialWithAccessCodeMutation({});

  const brandColor = useColorModeValue('brand.500', 'white');

  const uploadFile = useCallback(
    async (acceptedFiles: File[]) => {
      console.debug('Handle files', acceptedFiles);
      const newDocuments: JobDocument[] = [];

      await Promise.all(
        acceptedFiles.map(async file => {
          try {
            const jobDocumentResult = await createJobDocument({ name: file.name, jobId }).unwrap();
            const formData = new FormData();
            formData.set('inputFile', file);
            const uploadedDocument = await uploadJobDocument({ id: jobDocumentResult.id, formData }).unwrap();
            newDocuments.push(uploadedDocument);
          } catch (e) {
            console.error('Fail upload file', file, e);
          }
        }),
      );

      addDocumentsToPolling(newDocuments);
    },
    [addDocumentsToPolling, createJobDocument, jobId, uploadJobDocument],
  );

  const addCertidaoWithAccessCodeHandler = async (accessCode: string) => {
    try {
      setErrorModalAdditionalText(undefined);
      const addedDocuments = await addCertidaoWithAccessCode({
        jobId,
        dto: {
          code: accessCode,
        },
      }).unwrap();
      addDocumentsToPolling(addedDocuments);
    } catch (e: any) {
      console.error('Failed to add Certidao with access code', accessCode, e);
      if (
        e.data?.message?.includes('format is incorrect') ||
        e.data?.message?.includes('A certidão permanente indicada expirou o prazo de validade.')
      ) {
        setErrorModalAdditionalText(e.data?.message);
      }

      onDocumentsValidationError(DocumentsValidationErrorTypeEnum.CertidaoAccessCodeInvalid);
    }
  };

  const addCertidaoRegistoComercialWithAccessCodeHandler = async (
    accessCode: string,
    relationType: CertidaoRegistoComercialRelationType,
  ) => {
    try {
      setErrorModalAdditionalText(undefined);
      const addedDocuments = await addCertidaoRegistoComercialWithAccessCode({
        jobId,
        dto: { code: accessCode, relationType },
      }).unwrap();
      addDocumentsToPolling(addedDocuments);
    } catch (e: any) {
      console.error('Failed to add Certidao Registo Comercial with access code', accessCode, e);
      if (
        e.data?.message?.includes('format is incorrect') ||
        e.data?.message?.includes('Não existe qualquer certidão activa com esse número.') ||
        e.data?.message?.includes('Não existe qualquer certidão com esse número.')
      ) {
        setErrorModalAdditionalText(e.data?.message);
      }

      onDocumentsValidationError(DocumentsValidationErrorTypeEnum.CertidaoRegistoComercialAccessCodeInvalid);
    }
  };

  const addCadernetaWithAccessCodeHandler = async (data: CadernetaValidationData) => {
    try {
      const addedDocuments = await addCadernetaWithAccessCode({
        jobId,
        dto: {
          codeIssuerTIN: data.issuingNIF,
          codeValidation: data.validationCode,
        },
      }).unwrap();
      addDocumentsToPolling(addedDocuments);
    } catch (e) {
      console.error('Failed to add Caderneta with access code', data, e);
      onDocumentsValidationError(DocumentsValidationErrorTypeEnum.CadernetaAccessCodeInvalid);
    }
  };

  return (
    <>
      <Card flexDirection="column" w="100%" h="auto">
        <Flex direction="column" gap="24px" justify="space-between">
          <Flex gap="24px" direction={{ base: 'column', md: 'column' }}>
            <Dropzone
              w="100%"
              h="auto"
              minH={{ base: '7.5em' }}
              onDrop={uploadFile}
              content={
                <Box>
                  <Icon as={MdUpload} w="32px" h="32px" color={brandColor} />
                  <Flex direction="column" justify="center" mx="auto" mb="12px">
                    <Text fontSize="xl" fontWeight="700" color={brandColor}>
                      {t('pages.details.uploadFiles')}
                    </Text>
                    <Text color="secondaryGray.500" fontSize="12px" fontWeight="500" lineHeight="20px">
                      {t('pages.details.youCanDragAndDrop')}
                    </Text>
                  </Flex>
                </Box>
              }
            />
            <Flex direction="row" gap="12px">
              <ModalCadernetaValidation
                callback={addCadernetaWithAccessCodeHandler}
                isLoading={isLoadingCadernetaWithAccessCode}
                isOpen={isCadernetaModalOpen}
                onOpen={() => setIsCadernetaModalOpen(true)}
                onClose={() => setIsCadernetaModalOpen(false)}
                jobId={jobId}
                uploadedDocs={uploadedDocs}
              />
              <ModalCertidaoPerdialValidation
                callback={addCertidaoWithAccessCodeHandler}
                isLoading={isLoadingCertidaoWithAccessCode}
                isOpen={isCertidaoModalOpen}
                onOpen={() => setIsCertidaoModalOpen(true)}
                onClose={() => setIsCertidaoModalOpen(false)}
                jobId={jobId}
                uploadedDocs={uploadedDocs}
              />
              <ModalCertidaoRegistoComercialValidation
                callback={addCertidaoRegistoComercialWithAccessCodeHandler}
                isLoading={isLoadingCertidaoRegistoComercialWithAccessCode}
                isOpen={isCertidaoRegistoComercialModalOpen}
                onOpen={() => setIsCertidaoRegistoComercialModalOpen(true)}
                onClose={() => setIsCertidaoRegistoComercialModalOpen(false)}
              />
            </Flex>
          </Flex>

          <JobCategorizeFiles
            jobId={jobId}
            onShowUserSelectedUnsupportedTypeModal={onShowUserSelectedUnsupportedTypeModal}
            addDocumentsToPolling={addDocumentsToPolling}
            uploadedDocs={uploadedDocs}
            onUploadedDocs={onUploadedDocs}
            showHeader={false}
          />
        </Flex>
      </Card>

      <ModalDocumentsValidationError
        jobId={jobId}
        errorType={documentsValidationError}
        errorModalAdditionalText={errorModalAdditionalText}
        onCadernetaButtonClick={() => setIsCadernetaModalOpen(true)}
        onCertidaoButtonClick={() => setIsCertidaoModalOpen(true)}
        onClose={() => onDocumentsValidationError(null)}
      />

      <ModalDocumentTypeValidationError
        isOpen={isShowUserSelectedUnsupportedTypeModal}
        onClose={() => onShowUserSelectedUnsupportedTypeModal(false)}
      />
    </>
  );
}
