import { Box } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmissionSigningStatus } from '../../../api';
import {
  signingDefaultLanguage,
  SigningLanguage,
  useCloneSigningMutation,
  useDeleteSigningMutation,
  useLazyGetSigningQuery,
  useSubmitSigningMutation,
  useUpdateSigningMutation,
} from '../../../api/signing';
import { useDebounce } from '../../../hooks/useDebounce';
import { useTypedSelector } from '../../../store';
import { SigningDetailsWidget } from './widgets/SigningDetailsWidget';
import { useAppToast } from '../../../libs/ui/hooks';

export const SigningDetailPage = () => {
  const { t } = useTranslation();
  const { showSuccess, showApiError } = useAppToast();
  const navigate = useNavigate();

  const { signingId } = useParams<{ signingId: string }>();
  const selectedSigning = useTypedSelector(state => state.signing.selectedSigning ?? undefined);
  const signing = signingId === selectedSigning?.id ? selectedSigning : undefined;

  const [fetchSigning, { data, isLoading }] = useLazyGetSigningQuery({
    pollingInterval: signing?.submission?.status === SubmissionSigningStatus.PREPARING ? 700 : 0,
  });

  const [submitSigning, { isLoading: isSubmitting }] = useSubmitSigningMutation();
  const [updateSigning, { isLoading: isloadingUpdate }] = useUpdateSigningMutation();
  const [deleteSigning] = useDeleteSigningMutation();
  const [cloneSigning] = useCloneSigningMutation();
  const [name, setName] = useState<string>(signing?.entity?.name ?? signing?.name ?? '');
  const [isSerial, setIsSerial] = useState<boolean>(signing?.isSerial ?? true);
  const [language, setLanguage] = useState<SigningLanguage>(signing?.language ?? signingDefaultLanguage);
  const debouncedName = useDebounce(name, { delay: 300 });

  const load = useCallback(() => {
    fetchSigning({ signingId: signingId ?? '' });
  }, [fetchSigning, signingId]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (!signing) {
      return;
    }

    const newName = signing.entity?.name ?? signing.name ?? '';
    if (name !== newName) {
      setName(newName);
    }

    const newIsSerial = signing.isSerial ?? true;
    if (isSerial !== newIsSerial) {
      setIsSerial(newIsSerial);
    }

    const newLanguage = signing.language ?? SigningLanguage.EN;
    if (language !== newLanguage) {
      setLanguage(newLanguage);
    }
  }, [signing]);

  const handleSubmit = useCallback(async () => {
    if (!signingId) {
      throw new Error('Signing ID is not defined');
    }

    try {
      await submitSigning({ id: signingId }).unwrap();
      load();
    } catch (error) {
      showApiError(error);
    }
  }, [load, signingId, submitSigning, showApiError]);

  const handleUpdateSigning = useCallback(
    async (data: { name?: string; isSerial?: boolean; language?: SigningLanguage }) => {
      if (isloadingUpdate) {
        return;
      }

      if (!signingId) {
        throw new Error('Invalid application state! Signing ID is not defined');
      }

      try {
        await updateSigning({ id: signingId, ...data }).unwrap();
        load();
      } catch (error) {
        showApiError(error);
      }
    },
    [isloadingUpdate, load, signingId, showApiError, updateSigning],
  );

  const handleDeleteSigning = useCallback(async () => {
    if (!signingId) {
      throw new Error('Signing ID is not defined');
    }

    try {
      await deleteSigning({ id: signingId }).unwrap();
      showSuccess(t('pages.signings.actions.deletedSuccessfully'));
      navigate('/signings', { replace: true });
    } catch (error) {
      showApiError(error);
    }
  }, [deleteSigning, signingId, showApiError, navigate]);

  useEffect(() => {
    const initialName = signing?.name ?? '';
    if (!debouncedName || initialName === debouncedName) {
      return;
    }

    handleUpdateSigning({ name: debouncedName });
  }, [debouncedName, handleUpdateSigning, signing?.name]);

  const handleSerialToggle = useCallback(
    (value: boolean) => {
      setIsSerial(value);
      handleUpdateSigning({ isSerial: value });
    },
    [handleUpdateSigning],
  );

  const handleLanguageChange = useCallback(
    (value: SigningLanguage) => {
      setLanguage(value);
      handleUpdateSigning({ language: value });
    },
    [handleUpdateSigning],
  );

  const handleCloneRejectedSigning = useCallback(async () => {
    if (!signingId) {
      throw new Error('Signing ID is not defined');
    }

    try {
      const newSigning = await cloneSigning({ id: signingId }).unwrap();
      showSuccess(t('pages.signings.actions.clonedSuccessfully'));
      navigate(`/signings/${newSigning.id}`, { replace: true });
    } catch (error) {
      showApiError(error);
    }
  }, [cloneSigning, signingId, showSuccess, t, showApiError, navigate]);

  useEffect(() => {
    if (signing?.submission?.status === SubmissionSigningStatus.READY_TO_SEND) {
      navigate(`/signings/${signingId}/confirm`, { replace: true });
    }
  }, [navigate, signing?.submission?.status, signingId]);

  if (isLoading) {
    return <Box>...{t('loading')}</Box>;
  }
  if (!signing) {
    return <Box>...{t('pages.signing.signingFetchError')}</Box>;
  }

  return (
    <SigningDetailsWidget
      signing={signing}
      name={name}
      isSerial={isSerial}
      language={language}
      onSerialToggle={handleSerialToggle}
      onLanguageChange={handleLanguageChange}
      onDocumentsChanged={() => {
        fetchSigning({ signingId: signing.id });
      }}
      onSubmit={handleSubmit}
      onDeleteSigning={handleDeleteSigning}
      isLoadingSubmit={isSubmitting}
      onSingersCountChanged={() => load()}
      onNameChanged={e => setName(e)}
      onCloneRejectedSigning={handleCloneRejectedSigning}
    ></SigningDetailsWidget>
  );
};
